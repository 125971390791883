/* App.css */
.app-container {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
}

.main-content {
  padding-top: 100px; /* Adjust to your header height */
  
}

.header-content {
  text-align: center;
  padding: 35px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  margin: 20px auto;
  max-width: 800px;
  font-size: 20px;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.background-video__content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between cards */
  
 
}

/* Product Card Styles */
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 10px;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 250px;
  text-align: center;
  z-index: 10; /* Ensure it is on top of the video */
}
.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.product-card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
  border-radius: 8px 8px 0 0;
}

.product-card h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: black;
}

.product-card p {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  color: #555;
}

.product-card button {
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  filter:opacity(80%);
}
.product-card button:hover {
  background-color: #000000;
    filter: opacity(60%);

}


/* Product Detail Styles */
.product-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.product-detail img {
  width: 300px;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.product-detail h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #0066cc;
}

.product-detail p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #555;
}

.product-detail button {
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.product-detail button:hover {
  background-color: #004080;
}

/* Cart Styles */
.cart {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.cart h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #0066cc;
}

.cart ul {
  list-style-type: none;
  padding: 0;
}

.cart li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

.cart li:last-child {
  border-bottom: none;
}

.cart button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cart button:hover {
  background-color: #cc0000;
}

.hidden {
  display: none;
}

.sticky-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.footer {
  position: relative;
  z-index: 10; /* Higher than product cards */
}

/*Main*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Styles below need to be looked at and made practical*/
/* RESET */
* {
  margin: 0;
 
 
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
}

/* BASE STYLES */
body {
  background-color: #e8f0f7;
  font-family: 'Inter', sans-serif;
  min-height: 500vh;
  overflow-x: hidden;
}

/* NORMAL STYLES */
.header {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  /*to edit the opacity effect change the percentage below */
  filter:opacity(95%);
  
}

.background-video__content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.top-bar {
  background-color: #333;
}

.top-bar__content {
  height: 30px;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 20px;
  font-size: 12px;
  color: white;
}

.icon {
  margin-right: 3px;
  color: rgba(255, 255, 255, 0.17);
}

.bottom-bar {
  background-color: #000000;
  
  
}

.bottom-bar__content {
  min-height: 60px;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;  
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10; /* Higher than product cards */
}

.logo {
  vertical-align: middle;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.logo__img {
  height: 30px;
}

.logo__text {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.nav {
  transition: all 0.3s ease-in-out;
}

.nav__list {
  display: flex;
  column-gap: 40px;
}



.nav__link {
  color: rgba(255, 255, 255, 0.75);
  transition: all 0.2s;
}

.nav__link:hover,
.nav__link:focus {
  color: rgba(255, 255, 255, 1);
}

.btn {
  color: #fff;
  background-color: #0071e3;
  padding: 8px 20px;
  border-radius: 1000px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:hover {
  background-color:  rgb(28, 128, 228);
}

.hamburger {
  cursor: pointer;
  display: none;
}

.bar {
  height: 2px;
  width: 27px;
  background-color: #fff;
  margin: 5px 0;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}

/* For JS */
.nav--open {
  left: 50% !important;
}

.hamburger--open .bar:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger--open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger--open .bar:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

/* MEDIA QUERIES */
@media (max-width: 650px) {
  .nav {
    position: fixed;
    top: 90px;
    left: -100%;
    transform: translateX(-50%);
    background-color: rgb(19, 22, 26);
    width: 100%;
    padding: 10px 0 25px;
  }

  .nav__list {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .nav__link {
    font-size: 14px;
  }

  .btn {
    font-size: 11px;
    padding: 7px 17px;
  }

  .hamburger {
    display: block;
  }
}

@media (max-width: 360px) {
  .top-bar__content {
    font-size: 10px;
  }
}
  /*Footer*/
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .background-video {
    flex: 0;
  }
  
  .footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  